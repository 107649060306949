@import "../../../node_modules/bulma/bulma.sass"
@import "../../styles/colors"

.contacts-content
  header
    +desktop
    display: flex
    justify-content: space-between

    h1.title
      margin-bottom: 0

    .left
      +desktop
      display: flex

      > *
        margin-right: 1rem

        +mobile
        button.is-danger
          margin: 1em 0

  .group
    margin-top: 2rem
  table
    thead
      tr
        th
          color: $darkBlue
    tbody
      tr
        background: white
        border-bottom: 10px solid $lightBlue
        td
          border: none
          .name
            line-height: 2.5rem
    td, th
      padding: 1em 1em !important
    .buttons
      .button
        font-size: .90rem
        font-weight: 600
        text-decoration: none
        &:hover, &:focus, &:active
          box-shadow: none
          background-color: transparent
          opacity: .85
        &:after
          content: ''
          display: inline-block
          background: #888
          margin: 0px 0px 0 34px
          height: 18px
          width: 1px
          position: absolute
          right: 0
        &.button:last-child
          &:after
            content: ''
            background: transparent
        &:after
          content: ''
          background: transparent
  .action-header
    text-align: right !important
    span
      padding-right: 60px
  .buttons.is-inline.is-pulled-right.no-border
    border: none
