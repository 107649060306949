@import "../../../node_modules/bulma/bulma.sass"

.modal.edit-contact
  .modal-card-title
    margin-bottom: 0

  img
    width: 70px
    height: 70px
    border-radius: 35px

  input.contact-name
    margin-bottom: .5rem

  .modal-card-foot
    justify-content: space-between

  .table-wrapper
    +mobile
      overflow: scroll

  .tabs ul
    margin: 0

  span.icon-text
    display: inline-block
    margin-right: 4px
    width: 22px
    height: 22px
    background: url("../../img/icon-text.svg") no-repeat center/22px

  span.icon-phone
    display: inline-block
    margin-right: 4px
    width: 22px
    height: 22px
    background: url("../../img/icon-phone.svg") no-repeat center/22px
