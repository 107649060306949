@import "../../../node_modules/bulma/bulma.sass"

.modal.edit-app
  .modal-card-title
    margin-bottom: 0

  input.contact-name
    margin-bottom: .5rem

  .modal-card-foot
    justify-content: space-between

  .table-wrapper
    +mobile
      overflow: scroll

  .tabs ul
    margin: 0

  span.icon-text
    display: inline-block
    margin-right: 4px
    width: 22px
    height: 22px
    background: url("../../img/icon-text.svg") no-repeat center/22px

  span.icon-phone
    display: inline-block
    margin-right: 4px
    width: 22px
    height: 22px
    background: url("../../img/icon-phone.svg") no-repeat center/22px

  .optionContainer
    margin: 0

  .select-widget
    & .grouped
      min-width: 100%
      display: flex
      alignItems: center
      justifyContent: space-between

      & > span:first-child
        flex: 1 1

      & > span:last-child
        flex: 0
        display: flex
        background-color: #EBECF0
        border-radius: 2em
        color: #172B4D
        font-size: 12px
        font-weight: normal
        padding: 0.15em 0.5em
        align-items: center
        justify-content: center

      & .select__control-is-focused
        z-index: 99999

