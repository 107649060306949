form
  .form-item
    margin-bottom: 1em
    
    &.center
      display: flex
      justify-content: center

  .buttons, .settings
    display: flex
    justify-content: space-between

  .buttons
    margin-top: 2em
    
    button
      flex: 1