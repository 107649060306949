@import "../../styles/colors.sass"

.buttons
  .button
    font-size: .90rem
    font-weight: 600
    text-decoration: none
    &:after
      content: ''
      display: inline-block
      background: #888
      margin: 0px 0px 0 0px
      height: 18px
      width: 1px
      position: absolute
      right: -8px
    &.button:last-child
      &:after
        content: ''
        background: transparent
  .remove
    color: $red !important
  .disable
    color: $orange