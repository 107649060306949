@import "../../../node_modules/bulma/bulma.sass"
@import "../../styles/colors"

.apps-content
  display: flex
  flex-direction: column
  height: calc(100vh - 66px)

  header
    +desktop
    display: flex
    justify-content: space-between

    h1.title
      margin-bottom: 0

    .left
      +desktop
      display: flex

      > *
        margin-right: 1rem

        +mobile
        button.is-danger
          margin: 1em 0
    
    .search
      display: flex
      flex-direction: row

      > *
        flex: 1 1 0px
        min-width: 150px

  .group
    margin-top: 2rem
    display: flex
    flex-grow: 10
    flex-direction: column

    .header
      display: flex
      flex-direction: row
      font-weight: 700
      
      div:first-child
        flex-grow: 10
    .table
      flex-grow: 10
      display: flex
      justify-content: center

      .virtual-table
        flex: 1 1 auto
        
        div:first-child
          flex-grow: 10
  table
    thead
      tr
        th
          color: $darkBlue
    tbody
      tr
        background: white
        border-bottom: 10px solid $lightBlue
        td
          border: none
          .name
            line-height: 2.5rem
      .app-list
        background: red
        height: 100px
