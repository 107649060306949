@import "../../../node_modules/bulma/bulma.sass"
@import "../../styles/colors"

.description-content
  & header
    +desktop
    display: flex
    justify-content: space-between

    h1.title
      margin-bottom: 0

    .left
      +desktop
      display: flex

      > *
        margin-right: 1rem

        +mobile
        button.is-danger
          margin: 1em 0

  .group
    margin-top: 2rem
  & table
    thead
      tr
        th
          color: $darkBlue
    tbody
      tr
        background: white
        border-bottom: 10px solid $lightBlue
        td
          border: none
          .name
            line-height: 2.5rem
    td, th
      padding: 1em 1em !important
    .buttons
      .button
        font-size: .90rem
        font-weight: 600
        text-decoration: none
        padding-right: 60px
        &:hover, &:focus, &:active
          box-shadow: none
          background-color: transparent
          opacity: .85
      .remove
        color: $red
      .disable
        color: $orange
        &:after
          content: ''
          background: transparent
  & .action-header
    text-align: right !important
    span
      padding-right: 60px
