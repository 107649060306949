@import "../../styles/colors.sass"
@import "../../../node_modules/bulma/bulma.sass"

nav.navbar
  background-color: $darkBlue
  display: flex
  flex-direction: column
  position: relative

  +mobile
    background-color: $darkerBlue

  +desktop
    min-height: 100vh
    height: 100%

  .logo
    background: url("../../img/pinwheel-logo-icon-white.png") no-repeat $darkerBlue
    background-position: 15px center
    background-size: 160px auto
    width: 100%
    height: 66px
    +mobile
      display: none
  
  .navbar-brand
    div, a
      +mobile
        color: $white
        
        &.navbar-item
          background: url("../../img/pinwheel-icon-leila-colors.png") no-repeat 10px center/40px
          padding-left: 65px

  .navbar-menu
    background-color: $darkBlue
    padding: 0

    +mobile
      &.is-active
        position: absolute
        top: 51px
        left: 0
        right: 0

  ul
    width: 100%
  
    a, span
      color: $white
      height: 54px
      line-height: 54px
      padding-top: 0
      padding-bottom: 0
      border-left: 4px solid transparent
      background: no-repeat 18px center
      padding-left: 45px
      background-size: 18px auto
      
      &.secondary
        background-color: #3D687A
        padding-left: 65px
        background-position: 38px center

      &:hover,
      &:focus,
      &.active
        background-color: $darkerBlue
        color: $white
        border-left-color: $blue

      &.phone
        background-image: url("../../img/icon-smartphone.svg")

      &.calendar
        background-image: url("../../img/icon-calendar.svg")

      &.home
        background-image: url("../../img/icon-home.svg")
      
      &.on-it
        background-image: url("../../img/icon-on-it.svg")
      
      &.at-it
        background-image: url("../../img/icon-at-it.svg")
      
      &.days-modes
        background-image: url("../../img/icon-wallet.svg")
      
      &.contacts
        background-image: url("../../img/icon-contacts.svg")