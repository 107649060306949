@import "../../styles/colors.sass"
@import "../../../node_modules/bulma/bulma.sass"

.dashboard-header
  margin: 0 -2rem 2rem
  background-color: $white
  display: flex
  justify-content: flex-end
  align-items: center
  position: relative
  +mobile
    margin: 0 -12px 2rem
  
  span.icon-notification
    display: inline-block
    margin-right: 4px
    width: 22px
    height: 22px
    background: url("../../img/icon-notification.svg") no-repeat center/22px
  
  .account
    margin-left: 1rem
    border-left: 1px solid #efefef
    
    .info
      display: flex
      align-items: center
      padding: 14px 1rem 14px 2rem
      cursor: pointer
      background: url("../../img/icon-small-down.svg") no-repeat 1rem 32px

      +mobile
        padding-right: 0

    &.open .info
      background-image: url("../../img/icon-small-right.svg")
      background-position: 1rem 29px

    .dropdown
      position: absolute
      background: white
      margin: 0
      width: 150px
      right: 0
      top: 66px
      z-index: 2

      ul
        margin: 0
        padding: 0
        list-style-type: none
        width: 100%

        li
          &.logout
            span
              background: url("../../img/icon-logout.svg") no-repeat 1rem 18px/18px

          span
            padding: 14px 2rem
            display: block
            text-align: center
            cursor: pointer

    div
      margin-right: 1rem
      color: $darkBlue

    img
      width: 38px
      height: 38px
      border-radius: 19px