@import "../../../node_modules/bulma/bulma.sass"

.auth-page
  display: flex
  background: white
  flex: 1
  flex-direction: row
  height: 100%
  min-height: 100vh
  
  .left
    background: url("../../img/samsung-j3-case-blue-transparent@2x.png") center/contain no-repeat #efefef
    display: none
    +desktop
      display: block

  .right
    display: flex
    justify-content: center
    align-items: center
    
    .center
      max-width: 400px
    
    .form-item
      margin-bottom: 1em